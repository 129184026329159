const box = {
    parent: null,
    queue: [],
    out: [],
    marble: {type: null, pos: null},
    grid: [
        ["","","","","","v","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
        ["","","","","","","","","","",""],
    ].map((row, j) => row.map((val, i) => ({value: val, pos: [i, j]})))
}

const initialState = {
    box: box,
}

let mode = "edit";

const toggleMode = () => {
    mode = mode === 'edit' ? 'play' : 'edit';
    draw();
    console.log(document.getElementsByClassName("drop-button"));
    if (mode === "play") {
        reset();
        document.getElementById("mode-button").innerText = "Reset & Edit";
        [...document.getElementsByClassName("drop-button")].forEach(el => el.hidden = false);
    }
    if (mode === "edit") {
        document.getElementById("mode-button").innerText = "Play!";
        [...document.getElementsByClassName("drop-button")].forEach(el => el.hidden = true);
    }
}
